<template>
  <div class="container">
    <div class="main">
      <div @click="handleBack" class="flexSt headerbox">
        <img src="../../assets//img/126.png" alt="" />
        {{ Info.name }} - {{ Info.class_name }}
        {{ Info.start_time }}
      </div>
      <div class="table_box">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeaderColor"
          :header-cell-class-name="cellClass"
          :cell-class-name="returnName">
          <el-table-column
            :index="indexMethod"
            type="index"
            label="排名"
            width="100"
            align="center">
          </el-table-column>

          <el-table-column
            label="姓名"
            min-width="180"
            align="center"
            prop="student_name">
          </el-table-column>
          <el-table-column min-width="100" label="性别" align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.gender == 1 ? "男" : "女" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="180" label="进度" align="center">
            <template slot-scope="scope">
              <div class="tip_gro">
                <div class="flexSb">
                  <div>进度</div>
                  <div>
                    {{ scope.row.point_index }}/{{ scope.row.point_count }}
                  </div>
                </div>
                <el-progress
                  :show-text="false"
                  :stroke-width="10"
                  :percentage="scope.row.progress"></el-progress>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="完成时间"
            min-width="120"
            prop="time"
            align="center">
          </el-table-column>
          <el-table-column label="平均心率" align="center" width="196">
            <template slot-scope="scope">
              <div class="class_name">
                {{ scope.row.heart ? scope.row.heart + "bpm" : "- -" }}
              </div>
              <!-- </el-tooltip> -->
            </template></el-table-column
          >
          <el-table-column
            label="体温"
            min-width="140"
            align="center"
            prop="sports_class_rate">
            <template slot-scope="scope">
              {{ scope.row.temp ? scope.row.temp + "℃" : "- -" }}
            </template>
            <!-- -->
          </el-table-column>
        </el-table>

        <div class="flexSe elpla">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { historyRank } from "@/api/games";
export default {
  data() {
    return {
      loading: false,
      Info: {
        name: "",
        id: "",
      },
      pageInfo: {
        pageSize: 10,
        page: 1,
        total: 0,
      },
      tableData: [],
    };
  },
  created() {
    if (this.$route.query.val) {
      this.Info = JSON.parse(this.$route.query.val);
      console.log(this.Info, "=this.Info ");
      this.handleGethistoryRank();
    }
  },
  computed: {
    indexMethod() {
      // 当前页数 - 1 * 每页数据条数 + 1
      return (this.pageInfo.page - 1) * this.pageInfo.pageSize + 1;
    },
  },
  methods: {
    handleGethistoryRank() {
      let data = {
        id: this.Info.id,
        per_page: this.pageInfo.pageSize,
        page: this.pageInfo.page,
      };
      historyRank(data).then((res) => {
        this.tableData = res.data.data;
        this.pageInfo.total = res.data.total;
      });
    },
    handleBack() {
      this.$router.go(-1);
    },
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },
    cellClass() {
      return "disableSelection";
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "16px",
        color: "#333333",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F7F7F7",
      };
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.handleGethistoryRank();
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.handleGethistoryRank();
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  padding: 20px;
  box-sizing: border-box;
  height: 98%;
  flex: 1;

  .main {
    flex: 1;
    background-color: #fff;
    border-radius: 0.05rem;
    padding: 0.4375rem 0.3125rem;
    min-height: 100%;
  }
  .headerbox {
    margin-bottom: 20px;
    img {
      width: 23px;
      height: 23px;
      margin-right: 15px;
    }
    font-size: 24px;
    cursor: pointer;
  }
  .table_box {
    /deep/.disableSelection {
      // background-color: pink;
      /deep/.cell {
        font-size: 18px;
        // background-color: red;
      }
    }
    /deep/.firstCell {
      .cell {
        overflow: unset !important;
      }
    }
    ::-webkit-scrollbar {
      width: 10px !important;
      height: 10px !important;
      // background-color: #ccc !important;
    }
    ::-webkit-scrollbar-track {
      // background-color: #bbbbbb;
    }
    /* 滚动条上的滚动滑块 */
    ::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }
  .elpla {
    margin-top: 30px;
  }
}
</style>
